import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-valuetext"]
const _hoisted_2 = { class: "vue-slider-dot-tooltip-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "dot",
    class: _normalizeClass(_ctx.dotClasses),
    "aria-valuetext": _ctx.tooltipValue?.toString(),
    onMousedownPassive: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dragStart && _ctx.dragStart(...args))),
    onTouchstartPassive: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragStart && _ctx.dragStart(...args)))
  }, [
    _renderSlot(_ctx.$slots, "dot", {}, () => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.handleClasses),
        style: _normalizeStyle(_ctx.dotStyle)
      }, null, 6)
    ]),
    (_ctx.tooltip !== 'none')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.tooltipClasses)
        }, [
          _renderSlot(_ctx.$slots, "tooltip", {}, () => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.tooltipInnerClasses),
              style: _normalizeStyle(_ctx.tooltipStyle)
            }, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.tooltipValue), 1)
            ], 6)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 42, _hoisted_1))
}