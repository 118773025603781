import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.marksClasses)
  }, [
    _renderSlot(_ctx.$slots, "step", {}, () => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.stepClasses),
        style: _normalizeStyle([
          _ctx.stepStyle,
          _ctx.mark.style || {},
          _ctx.mark.active && _ctx.stepActiveStyle ? _ctx.stepActiveStyle : {},
          _ctx.mark.active && _ctx.mark.activeStyle ? _ctx.mark.activeStyle : {},
        ])
      }, null, 6)
    ]),
    (!_ctx.hideLabel)
      ? _renderSlot(_ctx.$slots, "label", { key: 0 }, () => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.labelClasses),
            style: _normalizeStyle([
          _ctx.labelStyle,
          _ctx.mark.labelStyle || {},
          _ctx.mark.active && _ctx.labelActiveStyle ? _ctx.labelActiveStyle : {},
          _ctx.mark.active && _ctx.mark.labelActiveStyle ? _ctx.mark.labelActiveStyle : {},
        ]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.labelClickHandle && _ctx.labelClickHandle(...args)))
          }, _toDisplayString(_ctx.mark.label), 7)
        ])
      : _createCommentVNode("", true)
  ], 2))
}